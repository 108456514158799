@tailwind base;
@tailwind components;
@tailwind utilities;

.bgbody{
    background-image: url("./assets/img/2-28-3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed
}
.bgbody2{
    background-image: url("./assets/img/oud.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 215vh 50vw;
    background-position: center;
    background-attachment: fixed;
}
.bgbody3{
    background-image: url("./assets/img/dannie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 260vh;
    background-position: center;
    background-attachment: fixed;
}
.field {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 300px;
}

.scroll {
	width: 60px;
	height: 60px;
	border: 2px solid #333;
	border-radius: 50%;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	}
.scroll::before {
	content: '';
	position: absolute;
	top: 15px;
	left: 18px;
	width: 18px;
	height: 18px;
	border-left: 2px solid #333;
  border-bottom: 2px solid #333;
	transform: rotate(-45deg);
}	

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@-moz-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
 .scroll-btn {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
}
.scroll-btn > * {
	display: inline-block;
	line-height: 18px;
	font-size: 13px;
	font-weight: normal;
	color: #7f8c8d;
	color: #ffffff;
	font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
	letter-spacing: 2px;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
	color: #ffffff;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
	opacity: 0.8;
	filter: alpha(opacity=80);
}
.scroll-btn {
	position: relative;
	display: block;
	width: 35px;
	height: 55px;
	margin: 0 auto 20px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 3px solid white;
	border-radius: 23px;
}
.scroll-btn .mouse > * {
	position: absolute;
	display: block;
	top: 29%;
	left: 50%;
	width: 8px;
	height: 8px;
	margin: -4px 0 0 -4px;
	background: white;
	border-radius: 50%;
	-webkit-animation: ani-mouse 2.5s linear infinite;
	-moz-animation: ani-mouse 2.5s linear infinite;
	animation: ani-mouse 2.5s linear infinite;
}
.expired-notice {
	text-align: center;
	padding: 2rem;
	border: 1px solid #ebebeb;
	border-radius: 0.25rem;
	margin: 0.5rem;
  }
  
  .expired-notice > span {
	font-size: 2.5rem;
	font-weight: bold;
	color: red;
  }
  
  .expired-notice > p {
	font-size: 1.5rem;
  }
  
  .show-counter {
	padding: 0.5rem;
  }
  
  .show-counter .countdown-link {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.75rem;
	padding: 0.5rem;
	border: 1px solid #ebebeb;
	border-radius: 0.25rem;
	text-decoration: none;
	color: #000;
  }
  
  .show-counter .countdown {
	line-height: 1.25rem;
	padding: 0 0.75rem 0 0.75rem;
	align-items: center;
	display: flex;
	flex-direction: column;
  }
  
  .show-counter .countdown.danger {
	color: #ff0000;
  }
  
  .show-counter .countdown > p {
	margin: 0;
  }
  
  .show-counter .countdown > span {
	text-transform: uppercase;
	font-size: 0.75rem;
	line-height: 1rem;
  }
  